import React, { useEffect, useState } from "react";
import "./faculty.css";
import { connect } from "react-redux";
import BazeIntroVideo from "../../../images/video/baze_university.mp4";
import { Link, useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import NewsSection from "../../news/newssection";
import EventSection from "../../events/eventsection/eventsection";
import * as DOMPurify from "dompurify";

const defaultFacultyImage = "/course.avif";

function Faculty() {
  const { slug } = useParams();
  console.log("Current Slug:", slug);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    faculty: null,
    details: null,
    courses: [],
    staff: [],
    news: [],
    events: [],
  });

  const intro_video =
    slug === "faculty-of-medicine"
      ? "https://drive.google.com/file/d/1pEpiRQh0QKEJrRNQ7sNpVbSgCkW0_O9r/preview"
      : slug === "faculty-of-management-and-social-sciences"
      ? ""
      : slug === "environmental-sciences"
      ? "https://drive.google.com/file/d/11ynQaLMhSPsykfycFwmZDIjVaZECEVMz/preview"
      : slug === "engineering"
      ? "https://drive.google.com/file/d/1Ce4XQTRdvjTeXKCtDM_wVrjHweYCxZrZ/preview"
      : slug === "faculty-of-science-and-computing"
      ? ""
      : slug === "general-studies"
      ? "https://drive.google.com/file/d/1-6Boj41vIwkBEeoTBoJ9QHkU5uuazljy/preview"
      : slug === "postgraduate-school"
      ? "https://drive.google.com/file/d/1H-BjjjSBUDUoUrM2NINyvVMPClBdrHjb/preview"
      : slug === "law"
      ? "https://drive.google.com/file/d/1DnDxZPuL0GA4CpqE1YW1LsZ6siqlJbAm/preview"
      : BazeIntroVideo;

  useEffect(() => {
    const getFacultyData = async () => {
      try {
        setLoading(true);

        // Get faculty list and find current faculty
        const facultyResponse = await axios.get(
          `${serverLink}faculty/list/academics`
        );
        const currentFaculty = facultyResponse.data.find(
          (f) => f.Slug === slug
        );
        console.log("Current Faculty:", currentFaculty);

        if (!currentFaculty) {
          console.log("Faculty not found for slug:", slug);
          throw new Error("Faculty not found");
        }

        // Get faculty details (courses)
        const detailsResponse = await axios.get(`${serverLink}faculty/details`);
        console.log("All Courses:", detailsResponse.data);

        const facultyDetails = detailsResponse.data.filter(
          (d) => d.DepartmentID === currentFaculty.FacultyID.toString()
        );
        console.log("Filtered Courses:", facultyDetails);
        console.log(
          "DepartmentID we're looking for:",
          currentFaculty.FacultyID.toString()
        );
        console.log("Available DepartmentIDs:", [
          ...new Set(detailsResponse.data.map((d) => d.DepartmentID)),
        ]);

        // Get staff list
        const staffResponse = await axios.get(
          `${serverLink}staff/website/staff_list`
        );
        console.log("Staff List Response:", staffResponse.data);

        const facultyStaff = staffResponse.data.filter(
          (s) => s.FacultyCode === currentFaculty.FacultyCode
        );
        console.log("Faculty Staff:", facultyStaff);

        // Get news and events
        const [newsResponse, eventsResponse] = await Promise.all([
          axios.get(`${serverLink}news/list/active`),
          axios.get(`${serverLink}event/list/active`),
        ]);

        const facultyNews = newsResponse.data.filter(
          (n) => n.FacultyCode === currentFaculty.FacultyCode
        );

        const facultyEvents = eventsResponse.data.filter(
          (e) => e.FacultyCode === currentFaculty.FacultyCode
        );

        const finalData = {
          faculty: currentFaculty,
          details: {
            FacultyName: currentFaculty.FacultyName,
            FacultyColor: currentFaculty.FacultyColour,
            FacultyDean: currentFaculty.FacultyDean,
            FacultyDetail: "", // Add any faculty description if available
            courses: facultyDetails, // Check if this is populated
          },
          staff: facultyStaff,
          news:
            facultyNews.length > 0
              ? facultyNews
              : newsResponse.data.slice(0, 5),
          events:
            facultyEvents.length > 0
              ? facultyEvents
              : eventsResponse.data.slice(0, 5),
        };

        console.log("Final Data Structure:", finalData);
        console.log("Courses in final data:", finalData.details.courses);

        setData(finalData);
      } catch (error) {
        console.error("Error fetching faculty data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      console.log("Starting data fetch for slug:", slug);
      getFacultyData();
    }
  }, [slug]);

  console.log("Current Component State:", { loading, data });

  if (loading) {
    return (
      <Loader
        type="ThreeDots"
        color="#63656A"
        height={200}
        width={200}
        className="text-center"
      />
    );
  }

  if (!data.faculty || !data.details) {
    return <div>Faculty not found</div>;
  }

  return (
    <>
      <div className="layout-blocks-ucws-sidekick-cta container-fluid block sidekick-cta full-width full-bleed inline-bg-image bg-image">
        <div className="row red-back hleft">
          <div className="col-image">
            <picture>
              <img
                className="hgt"
                src={
                  data.details.FacultyImage
                    ? `${serverLink}public/uploads/faculty_uploads/${data.details.FacultyImage}`
                    : defaultFacultyImage
                }
                alt={data.faculty.FacultyName}
                style={{
                  width: "100%",
                  height: "400px",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </picture>
          </div>
        </div>
      </div>

      {/* Faculty Details Section */}
      <div className="container-fluid layout col1" style={{ margin: "20px" }}>
        <div className="row">
          <div className="layout-col col1 layout-12 col-sm-12">
            <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
              <div className="row">
                <div className="container text-justify">
                  <div className="row header">
                    <div className="col-sm-12">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            data.details.FacultyDetail
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Welcome Note Section */}
      {/* <div className="container-fluid roundable block">
        <div className="row">
          <div className="container">
            <div
              className="row news-items light-grey-accents"
              style={{
                backgroundColor: "#f5f5f5",
                padding: "30px",
                borderTopLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <div className="col-sm-12 col-md-12 news-item news-items__main">
                <div className="row header">
                  <div className="col-sm-12">
                    <h2 style={{ color: data.details.FacultyColor }}>
                      <i className="fa fa-comment-o" /> Welcome Note
                    </h2>
                  </div>
                </div>

                <div className="row">
                  <div
                    className={
                      data.details.WelcomeNoteByDean ? "col-md-5" : "col-md-12"
                    }
                  >
                    <div className="news-item" style={{ marginTop: "10px" }}>
                      <div className="item-info discover-section">
                        <div className="row">
                          <div className="col-xs-12 col-md-12">
                            <div className="video-wrapper">
                              <iframe
                                src={intro_video}
                                width="100%"
                                height="300px"
                                frameBorder="0"
                                border="0"
                                cellSpacing="0"
                                sandbox
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {data.details.WelcomeNoteByDean && (
                    <div className="col-md-7">
                      <div className="news-item" style={{ marginTop: "10px" }}>
                        <div className="item-info discover-section">
                          <div className="row">
                            <div className="col-xs-12 col-md-12">
                              <div className="container text-justify">
                                <div className="row header">
                                  <div className="col-sm-12">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          data.details.WelcomeNoteByDean
                                        ),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Courses Section */}
      {data.details.courses.length > 0 && (
        <div className="container-fluid layout col1">
          <div className="row">
            <div className="layout-col col1 layout-12 col-sm-12">
              <div className="layout-blocks-ucws-profile-list container-fluid roundable block profile-list views-block">
                <div className="row">
                  <div className="container">
                    <div className="row header">
                      <div className="col-sm-12">
                        <h2 style={{ color: data.details.FacultyColor }}>
                          Available Courses
                        </h2>
                      </div>
                    </div>
                    <div className="row divider">
                      <div className="col-sm-12">
                        <hr />
                      </div>
                    </div>
                    <div className="profile-view-container">
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fill, minmax(300px, 1fr))",
                          gap: "20px",
                          padding: "20px",
                        }}
                      >
                        {data.details.courses.map((course, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor: "#f5f5f5",
                              borderRadius: "8px",
                              padding: "20px",
                              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                              transition: "transform 0.2s",
                              cursor: "pointer",
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.transform =
                                "translateY(-5px)")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.transform =
                                "translateY(0)")
                            }
                          >
                            <h3
                              style={{
                                color: data.details.FacultyColor,
                                fontSize: "1.2rem",
                                marginTop: 0,
                                marginBottom: "15px",
                              }}
                            >
                              {course.Description}
                            </h3>
                            <div className="course-details">
                              <p style={{ margin: "8px 0", display: "flex" }}>
                                <strong style={{ minWidth: "120px" }}>
                                  Course Code:
                                </strong>
                                <span>{course.CourseCode}</span>
                              </p>
                              <p style={{ margin: "8px 0", display: "flex" }}>
                                <strong style={{ minWidth: "120px" }}>
                                  Degree:
                                </strong>
                                <span>
                                  {course.CourseClass} {course.DegreeInView}
                                </span>
                              </p>
                              <p style={{ margin: "8px 0", display: "flex" }}>
                                <strong style={{ minWidth: "120px" }}>
                                  Duration:
                                </strong>
                                <span>
                                  {course.Duration} {course.DurationType}
                                </span>
                              </p>
                              {course.CourseCoordinator && (
                                <p style={{ margin: "8px 0", display: "flex" }}>
                                  <strong style={{ minWidth: "120px" }}>
                                    Coordinator:
                                  </strong>
                                  <span>{course.CourseCoordinator}</span>
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Staff Directory Section */}
      {data.staff.length > 0 && (
        <div className="container-fluid layout col1">
          <div className="row">
            <div className="layout-col col1 layout-12 col-sm-12">
              <div className="layout-blocks-ucws-profile-list container-fluid roundable block profile-list views-block">
                <div className="row">
                  <div className="container">
                    <div className="row header">
                      <div className="col-sm-12">
                        <h2 style={{ color: data.details.FacultyColor }}>
                          Faculty Staff Directory
                        </h2>
                      </div>
                    </div>
                    <div className="row divider">
                      <div className="col-sm-12">
                        <hr />
                      </div>
                    </div>

                    {/* Search Box */}
                    <div className="row" style={{ marginBottom: "20px" }}>
                      <div className="col-sm-12">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Search staff by name, designation..."
                            onChange={(e) => {
                              const searchTerm = e.target.value.toLowerCase();
                              const filteredStaff = data.staff.filter(
                                (staff) =>
                                  staff.staff_name
                                    ?.toLowerCase()
                                    .includes(searchTerm) ||
                                  staff.designation
                                    ?.toLowerCase()
                                    .includes(searchTerm)
                              );
                              setData((prev) => ({
                                ...prev,
                                staff: searchTerm ? filteredStaff : data.staff,
                              }));
                            }}
                            style={{
                              padding: "10px 15px",
                              borderRadius: "4px",
                              border: "1px solid #ddd",
                              width: "100%",
                              fontSize: "16px",
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="profile-view-container">
                      <ol className="profile-items-list no-bullet-display">
                        {data.staff.map((staff, index) => (
                          <li key={index} className="profile">
                            <p className="title">
                              <Link to={`/academics/staff/${staff.Slug}`}>
                                {`${staff.title || ""} ${staff.staff_name}`}
                              </Link>
                            </p>
                            <div className="roles">
                              <div className="role">
                                <div className="job-title">
                                  {staff.designation}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* News and Events Sections */}
      {data.news.length > 0 && (
        <NewsSection color={data.details.FacultyColor} news={data.news} />
      )}

      {data.events.length > 0 && (
        <EventSection
          color={data.details.FacultyColor}
          page="home"
          events={data.events}
        />
      )}
    </>
  );
}

export default Faculty;
