import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import BabaAhmedSquare from "../../../images/faculties/bau_banner.png";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { setManagementListDetails } from "../../../actions/setactiondetailsection";
import "./leadership.css";
import { connect } from "react-redux";
import HRLine from "../../utils/hrline";
import AlertDialog from "../../DialogBoxes/alert/alertdialog";
import { Link } from "react-router-dom";
import * as DOMPurify from "dompurify";
import { isMobile } from "react-device-detect";

class LeadershipComponent extends Component {
  constructor(props) {
    super(props);
    let board_members =
      this.props.leadershipList.length > 0 &&
      this.props.leadershipList.filter(
        (member) => member.Category === this.props.category
      );
    this.state = {
      leadership:
        this.props.leadershipList.length > 0 && this.props.leadershipList !== ""
          ? board_members
          : [],
      selectedImage: "",
      selectedTitle: "",
      selectedDescription: "",
      openAlertDialog: false,
      isCloseAlertDialog: true,
    };
  }

  componentDidMount() {
    this.getLeadership();
  }

  async getLeadership() {
    await axios
      .get(`${serverLink}leadership/list/active`)
      .then((result) => {
        const data = result.data;
        if (data.length > 0) {
          this.props.setOnLeadershipDetails(data);
          let board_members = data.filter(
            (member) => member.Category === this.props.category
          );
          this.setState({ leadership: board_members });
        }
      })
      .catch((err) => {
        console.log("NETWORK ERROR FETCHING ADMISSION DATELINE", err);
      });
  }

  openAlertDialog = (member) => {
    this.setState({
      selectedImage: member.ImagePath,
      selectedTitle: `${member.Title} ${member.FullName}`,
      selectedDescription: member.Description,
      openAlertDialog: true,
    });
  };

  closeAlertDialog = () => {
    this.setState({
      openAlertDialog: false,
      selectedImage: "",
      selectedTitle: "",
      selectedDescription: "",
    });
  };

  render() {
    let dynamic_col = [];
    const leadership = this.state.leadership.length - 1;
    dynamic_col = Array(leadership).fill(4);

    return (
      <>
        <Banner
          image={BabaAhmedSquare}
          title={this.props.title}
          caption={this.props.caption}
        />

        <AlertDialog
          openDialog={this.state.openAlertDialog}
          title={this.state.selectedTitle}
          isFullWidth={false}
          onSetOpenDialog={this.closeAlertDialog}
          isCloseBtnAppear={this.state.isCloseAlertDialog}
        >
          <div
            className="text-justify"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(this.state.selectedDescription),
            }}
          />
        </AlertDialog>

        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
          {this.state.leadership.length > 0 && (
            <div className="container-fluid layout col2-50-50">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="layout-col col2-50 layout-6 col-md-6">
                      <div className="layout-blocks-ucws-image container-fluid roundable block image">
                        <div className="row">
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="image-wrapper">
                                  <div>
                                    <img
                                      style={{
                                        borderRadius: "10px",
                                        maxHeight: isMobile ? "200px" : "400px",
                                      }}
                                      className="img-responsive-top"
                                      loading="lazy"
                                      src={
                                        this.state.leadership[0].ImagePath !==
                                          undefined &&
                                        this.state.leadership[0].ImagePath.includes(
                                          "simplefileupload"
                                        )
                                          ? this.state.leadership[0].ImagePath
                                          : `${serverLink}public/uploads/leaderships_uploads/${this.state.leadership[0].ImagePath}`
                                      }
                                      alt={`${this.state.leadership[0].Title} ${this.state.leadership[0].FullName}`}
                                      title={`${this.state.leadership[0].Title} ${this.state.leadership[0].FullName}`}
                                      typeof="foaf:Image"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="layout-col col2-50 layout-6 col-md-6">
                      <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                          <div className="container">
                            <div className="row left">
                              <div className="col-sm-12 one-col">
                                <h3>{`${this.state.leadership[0].Title} ${this.state.leadership[0].FullName}`}</h3>

                                <p className="text-wrap">
                                  <strong>
                                    {this.state.leadership[0].Designation}
                                  </strong>
                                </p>

                                {this.state.leadership[0].Description &&
                                  this.state.leadership[0].Description !==
                                    "\r\n" &&
                                  this.state.leadership[0].Description !==
                                    "<p>&nbsp;</p>\r\n" && (
                                    <div
                                      className="text-justify"
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          this.state.leadership[0].Description.replace(
                                            /\\r\\n/g,
                                            "<br />"
                                          ) || ""
                                        ),
                                      }}
                                    />
                                  )}
                                {/* {
                                                                this.props.category !== 'Board' &&
                                                                <Link to={`/staff/${this.state.leadership[0].Slug}`}>
                                                                    Read more
                                                                </Link>
                                                            } */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <HRLine />
                </div>
              </div>
            </div>
          )}

          {this.state.leadership.length > 1 && (
            <div className="container-fluid layout col3-33-33-33">
              <div className="row justify-content-start">
                {this.state.leadership
                  .slice(1)
                  .sort((a, b) => a.Position - b.Position)
                  .map((member, index) => (
                    <div
                      key={`board-${index}`}
                      className="col-md-4 col-sm-6 mb-4"
                    >
                      <div className="layout-blocks-ucws-image container-fluid roundable image">
                        <div className="image-wrapper">
                          <img
                            style={{
                              borderRadius: "10px",
                              height: isMobile ? "200px" : "320px",
                              width: "100%",
                              objectFit: "cover",
                              objectPosition: "center top",
                            }}
                            className="img-responsive"
                            loading="lazy"
                            src={
                              member.ImagePath?.includes("simplefileupload")
                                ? member.ImagePath
                                : `${serverLink}public/uploads/leaderships_uploads/${
                                    member.ImagePath || "placeit.jpg"
                                  }`
                            }
                            alt={`${member.Title} ${member.FullName}`}
                            title={`${member.Title} ${member.FullName}`}
                            typeof="foaf:Image"
                          />
                        </div>
                      </div>

                      <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="text-center">
                          {this.props.category !== "Board" ? (
                            <Link
                              to={`/academics/staff/${member.Slug}`}
                              style={{ backgroundImage: "none" }}
                            >
                              {this.props.category === "Senate" ? (
                                <b style={{ fontSize: "16px" }}>
                                  {`${member.Title} ${member.FullName}`}
                                </b>
                              ) : (
                                <h3>{`${member.Title} ${member.FullName}`}</h3>
                              )}
                            </Link>
                          ) : (
                            <h3>{`${member.Title} ${member.FullName}`}</h3>
                          )}

                          <p className="text-wrap">
                            <small>{member.Designation}</small>
                          </p>

                          {this.props.category !== "Senate" &&
                            member.Description && (
                              <p>
                                <span
                                  className="member_learn_more"
                                  onClick={() => this.openAlertDialog(member)}
                                >
                                  Learn more
                                </span>
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    leadershipList: state.BazeManagementListDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLeadershipDetails: (p) => {
      dispatch(setManagementListDetails(p));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadershipComponent);
