const status = "Live";

export const serverStatus = status;
export const serverLink =
  status === "Dev"
    ? "http://localhost:4480/"
    : // : "https://api.bazeuniversity.edu.ng:5001/";
      "https://api.babaahmeduniversity.edu.ng:5001/";

export const _assets = "https://portal.babaahmeduniversity.edu.ng/staff/";
