import React from "react";
import "./utils/notice-pop-up.css";

export default function ICEEventPopUp() {
  return (
    <div>
      <div id="ice-event" className="pop_up_overlay">
        <div className="pop_up_popup">
          <a className="close" href="#">
            &times;
          </a>
          <div className="pop_up_content">
            <img
              style={{ width: "100%" }}
              src={require("./images/organizers.jpg")}
            />

            <p>
              Baba Ahmed University Invites you to the{" "}
              <strong>
                International Conference and Exhibition On Sustainable
                Entrepreneurship And Leadership Development{" "}
              </strong>{" "}
            </p>
            <a
              href="/international-conference-and-exhibition-on-sustainable-entrepreneurship-and-leadership-development"
              className="btn btn-main-md w-100"
            >
              View Details
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
