import React, { Component, useState } from "react";
import "./footer.css";
import Facebook from "../../images/social-icon-facebook-round.svg";
import Twitter from "../../images/social-icon-twitter-round.svg";
import LinkedIn from "../../images/social-icon-linkedin-round.svg";
import Instagram from "../../images/social-icon-instagram-round.svg";
import YouTube from "../../images/social-icon-youtube-round.svg";
import { Link } from "react-router-dom";
import Bronchure from "../footer/baze_brochure.pdf";
import NewsLetter16A from "../footer/Baze_University_Brochure.pdf";
import ITRINewsletter from "../footer/ResearchandInnoationNewsletter.pdf";
import AlertDialog from "../DialogBoxes/alert/alertdialog";

const Footer = () => {
  const date = new Date();
  const this_year = date.getFullYear();
  const [dt, setDt] = useState({
    select: [],
    openAlertDialog: false,
    openAlertPolicyDialog: false,
    isCloseAlertDialog: true,
    isCloseAlertPolicyDialog: true,
    openAlertDisclaimerDialog: false,
    isCloseopenAlertDisclaimerDialog: true,
  });
  const openAlertDialog = () => {
    setDt({
      ...dt,
      openAlertDialog: true,
    });
  };
  const closeAlertDialog = () => {
    setDt({
      ...dt,
      openAlertDialog: false,
    });
  };

  const openPolicyAlertDialog = () => {
    setDt({
      ...dt,
      openAlertPolicyDialog: true,
    });
  };
  const closePolicyAlertDialog = () => {
    setDt({
      ...dt,
      openAlertPolicyDialog: false,
    });
  };

  const openDisclaimerAlertDialog = () => {
    setDt({
      ...dt,
      openAlertDisclaimerDialog: true,
    });
  };
  const closeDisclaimerAlertDialog = () => {
    setDt({
      ...dt,
      openAlertDisclaimerDialog: false,
    });
  };
  return (
    <footer className="container-fluid flex-column" style={{ height: "auto" }}>
      <div id="footer" className="row">
        <div id="footer-redline" className="red-back">
          <div />
        </div>
        <div
          className="container-fluid"
          style={{ paddingTop: "35px", marginBottom: "20px" }}
        >
          <div className="row">
            <div
              id="footer-links"
              className="col-md-4"
              style={{ fontSize: "14px" }}
            >
              <div className="row">
                <div className="col-md-6">
                  <h4
                    style={{ textDecoration: "underline", marginLeft: "15px" }}
                  >
                    Quick Links
                  </h4>
                  <ul className="links" style={{ listStyleType: "none" }}>
                    <li>
                      <Link className="btn-social" to="/about/about-baze">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-social" to="/about/our-campus">
                        Our Campus
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-social" to="/events">
                        Events
                      </Link>
                    </li>
                    <li>
                      <Link className="btn-social" to="/news">
                        News
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://portal.bazeuniversity.edu.ng/jobs/"
                        target={"_blank"}
                        className="btn-social"
                      >
                        Careers
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h4
                    style={{ textDecoration: "underline", marginLeft: "15px" }}
                  >
                    Resources
                  </h4>
                  <ul className="links" style={{ listStyleType: "none" }}>
                    {/*<li>*/}
                    {/*    <a className="btn-social" href="https://issuu.com/bazeuniversity" target={"_blank"} >*/}
                    {/*        Baze Panaroma*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    <li>
                      <Link
                        className="btn-social"
                        to="/resources/staff-publication"
                      >
                        Publications
                      </Link>
                    </li>
                    {/*<li>*/}
                    {/*    <a className="btn-social" href={Bronchure} target="_blank" >*/}
                    {/*        Baze Brochure*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    {/* <li>
                                            <Link className="btn-social" to="/leadership/board">
                                                Leadership
                                            </Link>
                                        </li> 
                                        {/*<li>*/}
                    {/*    <a className="btn-social" href={`https://drive.google.com/drive/folders/1g61sO-tlLRJc7iXzE9XMSLUnZ7MRRDzU`} target="_blank" >*/}
                    {/*        ITR & I Newsletter*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                  </ul>
                </div>
              </div>
            </div>
            <div
              id="footer-links"
              className="col-md-4"
              style={{ fontSize: "14px" }}
            >
              <h4 style={{ textDecoration: "underline", marginLeft: "15px" }}>
                Directorates & Units
              </h4>
              <ul style={{ listStyleType: "none" }}>
                {/*<li>*/}
                {/*    <Link className="btn-social" to="/itri-office">*/}
                {/*        IT, Research and Innovation Office*/}
                {/*    </Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <Link className="btn-social" to="/strategy-and-specia-duties">*/}
                {/*        Directorate of Strategy & Special Duties*/}
                {/*    </Link>*/}
                {/*</li>*/}
                <li>
                  <Link className="btn-social" to="/security">
                    Directorate of Security Services
                  </Link>
                </li>
                <li>
                  <Link className="btn-social" to="/student-support">
                    Student Support Unit
                  </Link>
                </li>
              </ul>
            </div>

            <div id="footer-deets" className="col-md-4">
              <div className="row">
                <div className="col-md-6">
                  <div id="footer-links">
                    <h4 style={{ textDecoration: "underline" }}>
                      Connect With Us
                    </h4>
                    <ul className="social">
                      <li>
                        <a
                          className="btn-social"
                          href="https://www.facebook.com/Baba-Ahmed-University-Kano/?_rdc=1&_rdr"
                          target="_blank"
                        >
                          <img
                            className="svg ucws-social-icon facebook"
                            src={Facebook}
                            alt="Facebook"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn-social"
                          href="https://twitter.com/BazeUniversity"
                          target="_blank"
                        >
                          <img
                            className="svg ucws-social-icon twitter"
                            src={Twitter}
                            alt="Twitter"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn-social"
                          href="https://www.linkedin.com/school/bazeuniversity/mycompany/"
                          target="_blank"
                        >
                          <img
                            className="svg ucws-social-icon linkedin"
                            src={LinkedIn}
                            alt="LinkedIn"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="btn-social"
                          href="https://www.youtube.com/@bazeuniversitysocialmedia5022"
                          target="_blank"
                        >
                          <img
                            className="svg ucws-social-icon youtube"
                            src={YouTube}
                            alt="youtube"
                          />
                        </a>
                      </li>
                    </ul>
                    <p className="legalese">
                      {/* <a href="https://drive.google.com/drive/folders/1wfwHFAsws7kNhpyR9MNGdIPtv3rH8qyN" target={"_blank"}>ITR & I Policy</a><br /> */}
                      <a href="#" onClick={openPolicyAlertDialog}>
                        Privacy Policy
                      </a>
                      <br />
                      <a href="#" onClick={openAlertDialog}>
                        Accessibility Policy
                      </a>
                      <br />
                      <a href="#" onClick={openDisclaimerAlertDialog}>
                        Disclaimer
                      </a>
                      <br />
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <p className="address">
                    Baba Ahmed University, Kano
                    <br />
                    phase 1, No 306, Sharada, Kano 700234, Kano
                    <br />
                  </p>
                  <p className="copyright">Copyright &copy; {this_year}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AlertDialog
        openDialog={dt.openAlertDialog}
        title={"BAZE UNIVERSITY WEB ACCESSIBILITY"}
        isFullWidth={true}
        onSetOpenDialog={closeAlertDialog}
        isCloseBtnAppear={dt.isCloseAlertDialog}
      >
        <div className="text-justify">
          This statement applies to content published on the university website
          and any website sub-domains of Baba Ahmed University, Kano. Nigeria.
          The sites and sub domains of Baba Ahmed University are managed locally
          and will publish statements with information relevant to them. These
          include websites of our student portal, staff portal, transcript
          portal, among others. We would like to ensure that our website is as
          accessible as possible, for this reason, we are continuously improving
          its usability and accessibility. We have used the usability models of
          Leventhal and Barnes as well as the guidelines of the web content
          accessibility (WCAG) 2.1 to keep to usability and accessibility
          standards. At the moment, research. website is:
          <ol>
            <li>
              Compatible with all major browsers including chrome, firefox, edge
              etc
            </li>
            <li>
              Works very well on any screen size as it can reposition in ways
              that do not enable text or image loss.
            </li>
            <li>Navigate most of the website using just a keyboard</li>
            <li>Zoom in up to 500% without hindering text or image</li>
            <li>
              Listen to most of the website using a screen reader (Google chrome
              screen reader).
            </li>
            <li>View essential audio-visual information with no hassle</li>
          </ol>
          <hr />
          We have noted that some parts of this website and its subdomains are
          not fully accessible. For example:
          <ol>
            <li>
              Some sections cannot be navigated with just a keyboard (mega
              menus)
            </li>
            <li>
              Some older PDF documents may not be fully accessible to screen
              reader software
            </li>
            <li>Some videos may not have captions or audio descriptions</li>
          </ol>
          <hr />
          Please see the Technical Information section below for more detailed
          information on known accessibility issues.
          <h5>
            <strong>
              TECHNICAL INFORMATION ABOUT THIS WEBSITE’S ACCESSIBILITY
            </strong>
          </h5>
          We are committed to making our website accessible in accordance with
          the relevant accessibility guidelines. This website is partially
          compliant with the WCAG 2.1 standard, due to the instances of
          non-compliance and exceptions listed below.
          <hr />
          <h5>
            <strong>NON-ACCESSIBLE CONTENT</strong>
          </h5>
          The content listed below is non-accessible for the following reasons:
          <hr />
          <ol>
            <li>
              <h5>
                <strong>
                  NON-COMPLIANCE WITH THE ACCESSIBILITY REGULATIONS: NAVIGATION,
                  TEXT & IMAGES
                </strong>
              </h5>
              <p>
                Some images do not have a text alternative, so people using a
                screen reader cannot access the information. Colour contrast may
                not be sufficient on some webpages which could make these pages
                difficult to read for some users with visual impairments. Our
                mega menus are not accessible using a keyboard so people using a
                screen reader may find navigating our website difficult.
              </p>
            </li>
            <li>
              <h5>
                <strong>
                  CONTENT THAT’S NOT WITHIN THE SCOPE OF THE ACCESSIBILITY
                  REGULATIONS
                </strong>
              </h5>
              <p>- PDFs and other documents.</p>
              <p>
                - Third party-content: our site uses third-party content which
                may not be fully accessible. This includes content we publish on
                social media channels such as Twitter or YouTube, or documents
                of news and panorama hosted on the digital platform Issuu. We
                are responsible for ensuring the accessibility of the content we
                produce, however we have no control over the accessibility of
                these platforms. -WHAT WE ARE DOING TO IMPROVE ACCESSIBILITY TO
                OUR SITE - The IT team in coordination with the leadership of
                the university, will periodically monitor and improve the
                policies regarding accessibility of the website. We have system
                analysts passionate about usability and accessibility working
                transparently to ensure that accessibility issues are reviewed
                occasionally. We are continually working on our contents and
                site to make it more intuitive and easy to navigate.
              </p>
            </li>
            <li>
              <h5>
                <strong>REPORTING ACCESSIBILITY PROBLEMS ON OUR WEBSITE</strong>
              </h5>
              <p>
                Please email servicedesk@bazeuniversity.edu.ng if you have any
                accessibility query. Identify any problems not listed on this
                page or think we’re not meeting accessibility requirements.
                Please provide the web address (URL) of the page(s) in which you
                find the problem, a description of the issue and your name. We
                will consider your request and get back to you within 7 working
                days. If you are not happy with how we respond to your complaint
                via email please contact us on the following extensions
                1081/1005.
              </p>
            </li>
          </ol>
          <strong>Statement Updated: 30 Nov., 2022</strong>
        </div>
      </AlertDialog>

      <AlertDialog
        openDialog={dt.openAlertPolicyDialog}
        title={"BAZE PRIVACY POLICY DOCUMENT"}
        isFullWidth={true}
        onSetOpenDialog={closePolicyAlertDialog}
        isCloseBtnAppear={dt.isCloseAlertPolicyDialog}
      >
        <div className="text-justify">
          <h3>Privacy Policy</h3>
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You. We use Your Personal data to provide and improve the
            Service. By using the Service, You agree to the collection and use
            of information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the Free Privacy Policy
            Generator.
          </p>

          <h3>Collecting and Using Your Personal Data</h3>
          <h4>Types of Data Collected</h4>
          <h4>Personal Data</h4>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>

          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address</li>
          </ul>

          <h3>Usage Data</h3>
          <p>
            Usage Data is collected automatically when using the Service.
            <br />
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
            <br />
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </p>

          <h3>Tracking Technologies and Cookies</h3>
          <p>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
            <br />
            Cookies or Browser Cookies. A cookie is a small file placed on Your
            Device. You can instruct Your browser to refuse all Cookies or to
            indicate when a Cookie is being sent. However, if You do not accept
            Cookies, You may not be able to use some parts of our Service.
            Unless you have adjusted Your browser setting so that it will refuse
            Cookies, our Service may use Cookies.
            <br />
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. Learn more about cookies on the Free Privacy Policy
            website article.
            <br />
            These Cookies allow us to remember choices You make when You use the
            Website, such as remembering your login details or language
            preference. The purpose of these Cookies is to provide You with a
            more personal experience and to avoid You having to re-enter your
            preferences every time You use the Website.
          </p>

          <h3> Law enforcement</h3>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>

          <h3>Other legal requirements</h3>
          <p>
            Baba Ahmed University may disclose Your Personal Data in the good
            faith belief that such action is necessary to:
            <ul>
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                Protect the personal safety of Users of the Service or the
                public
              </li>
              <li>Protect against legal liability</li>
              <li>
                Security of Your Personal Data
                <div>
                  The security of Your Personal Data is important to Us, but
                  remember that no method of transmission over the Internet, or
                  method of electronic storage is 100% secure. While We strive
                  to use commercially acceptable means to protect Your Personal
                  Data, We cannot guarantee its absolute security.
                </div>
              </li>
            </ul>
          </p>

          <h3>Links to Other Websites</h3>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
            <br />
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>

          <h3>Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
            <br />
            By email:{" "}
            <a href="mailto:servicedesk@bazeuniversity.edu.ng">
              servicedesk@bazeuniversity.edu.ng
            </a>
            <br />
            By visiting this page on our website:{" "}
            <a href="https://bazeuniversity.edu.ng/" target={"_blank"}>
              https://bazeuniversity.edu.ng/
            </a>
          </p>
        </div>
      </AlertDialog>

      <AlertDialog
        openDialog={dt.openAlertDisclaimerDialog}
        title={"DISCLAIMER"}
        isFullWidth={true}
        onSetOpenDialog={closeDisclaimerAlertDialog}
        isCloseBtnAppear={dt.isCloseopenAlertDisclaimerDialog}
      >
        <div className="text-justify">
          <h3>BEWARE OF FRAUDSTERS</h3>
          <p>
            It has come to the knowledge of Baba Ahmed University that
            fraudsters are using the internet to defraud innocent people with
            false claims of providing admissions to the University.
            <br />
            Members of public are hereby warned to be on alert and endeavour to
            clarify directly with the University any suspicious solicitations
            for admissions.
          </p>
        </div>
      </AlertDialog>
    </footer>
  );
};

export default Footer;
